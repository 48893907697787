@import './variable.scss';


%displayf {
    display: flex;
    justify-content: center;
    align-items: center;
}
h3{
    
    padding-left: calc(var(--bs-gutter-x) * .5);
    color: $gray;
    margin-top: .5rem;
}
p{
    margin: 0;
}
.tableau_main{
    padding: .5rem;
    margin-left: .5rem;
   
}



.graphMiddle{
    width: 48%;
    margin: auto;
    
}
#my-chart.line {
    height: 200px;
    max-width: 400px;
    margin: 0 auto;
  }

.div_graph{
    border-radius: 20px;
    background-color: antiquewhite;
    margin: 1rem 0.5rem 0 0;
    box-shadow: 8px 9px 12px 4px rgb(179, 184, 189);


}

.chart1_2{
    height:22rem;
}

.photo{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px black solid;
    @extend %displayf;
    overflow: hidden;
}

.userDiv{
    display: grid;
    grid-template-columns: 1fr 3fr;

}

.chart-container {

    max-height: 100%; /* Limite la hauteur maximale à 100% */
  
   @extend %displayf;
    canvas{
        width: 100%;
    }
}




//********divDebut

.div h3{
    margin-left: 0;
    margin-bottom: 0;
    color: $blue;
    margin-bottom: .5rem;
  
    
}
.test{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;


}
@media #{$media}{
    .test{
        display: flex;
        flex-direction: column;

        .div_graph{
            width: 95%;
        }
    }
    .div{

     
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .contentDebut{
      display: flex;
        flex-direction: column;
       align-items: center;
       justify-content: center;
    }
    .colorNumber,.colorText{
        height: 50%;
    width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
    }
    
}
.contentDebut{
    padding: 1.5rem;
}
.colorText,.colorIcon,.colorNumber{
    width: 50%;
}
.colorText,.colorNumber{
    height: 50%;

}
.colorIcon{
   
    color:$blue;

    i{
        font-size: 50px;
    }

}
.colorText{
    color: $blue;
}
//***** table
  table{
    width: 100%;
    td{
        border-bottom: 1px solid $green;
        height: 5rem;
     }
  }
 .tableUser{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: .5rem;
    margin-left: .5rem;
 }


 .photoUsers{
max-width: 300%;
 }


.carto{
    width: 100%;
    position: relative;
}


tbody tr td p{
    vertical-align: bottom;
}
@media screen and (min-width:801px) {
    .colorStatut{
        padding: 0.1rem;
        border-radius: 15px;
        vertical-align: bottom;
        max-width:50%;
        text-align: center;
     }
}
@media screen and (max-width:800px) {
    .tableUser{
        width: 98%;
        margin-left: 0.1rem;
    }

    table thead{
        display: none;
    }
    table tr{
        display: block;
        margin-bottom: 40px;
    }
    table td {
        display: block;
        text-align: right;
    }
    .colorStatut{
        display: block;
        text-align: right;
      width: 100%;
        border-radius: 5px;
    }

    table td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        }
    
}

 //**********line
 .lineDiv{
    height: 20rem;
 }

 h2{
    margin-top: 4rem;
 }

 //*****user

 .userDiv{
    margin-bottom: 1rem;
}
.scroller{
    overflow:scroll;
    overflow-x: hidden;
    max-height: 52rem;
}



//*********responsive
@media screen and (min-width: 801px){
    .headerNav{
        display: none;
    }
    .userDiv{
      margin-left: 1rem;

  }


    .total{
      
        display: grid;
       
     
        grid-template-columns: $grid-template-columns;
        margin: 58px 0 50px 0;
    
    }

    .left_nav{
      
        color: antiquewhite;
        position: relative;
    }
    .margin_fix{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

 }
 @media screen and (max-width: 800px){

    .total{
        display: flex;
        position: relative;
    }
    .tableau_main{
        width: 100%;
    }

    .graphMiddle{
        width: 90%;
        height: auto;
    }
    .tableUser{
        display: flex;
    }
    .users{
        width: 95%;
        margin: auto;
    }

  
    .root{
        width: 100%;
    }
    .total{
        width: 100%;
        padding: 0;
        margin: 5rem 0 0 0;
    }
    .userDiv{
        margin: auto;
       
        margin-bottom: 1rem;
        border-bottom: 1px solid $green;
    }
   


//menu burgur
#menuContent.showMenu{
    display: flex !important;
 left: 0;
 visibility: visible;
 width: 70px;
 height: 100%;
 justify-content: center;
 border-radius: 0 15px 15px 0;

   
  
  }
  .fixBar{
    position: fixed;
  }

.left_nav{
   visibility: hidden;
    
         left: -150px;
        
      height:100%;
      transition: left .5s ease; 

      position:fixed;
   
   
    
     /*
     
     border: 1px solid red;
     */  
      height: 100vh;
    // justify-content:center !important;
    z-index: 99;
       
     }
  
   


#burger1::before,
#burger1::after {
content:'';
width:20px;
/* margin-top: 1rem; */
height:3px;
background:rgb(214, 179, 133);
display:inline-block;
position:absolute;
transform-origin: center;
transition: all 0.3s ease;
}
#burger1::before {
top:-7px;
/* margin-bottom: 2rem;/ */
margin-top: 1px;
}
#burger1::after {
top:7px;
}
#burger1.opene {
    background:transparent;
}

#burger1.opene::before {
transform: rotate(45deg); 
top:0;
}
#burger1.opene::after {
transform: rotate(-45deg);
top:0;
}

#link{
display: flex;
color: rgb(222, 50, 50);
padding: 1rem;
right: 0;
top: 0;

margin-top: 1.2rem;
margin-right:1rem ;
z-index: 100;
position:fixed;
/*

border: solid 1px greenyellow;
*/ 

}
#burger1 {
width:20px;
margin-top: 5px;
height:3px;
background:rgb(212, 90, 14);
display:inline-block;
position:relative;

}
 }