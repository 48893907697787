@import './variable.scss';


.left_nav{
    background-color: $blue;
    color: antiquewhite;
    border-radius: 0 15px 15px 0;
    overflow: hidden;
    padding-top: 1.5rem;
 
  
}

.iconBar{
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    cursor: pointer;
    width: 100%;
    position: relative;
    
   

    @media screen and (min-width:801px)
     {
        
         
         &:hover{
        background-color: rgb(255, 255, 255);
        border-radius: 30px 0 0 30px;
        color:$blue;
        
        
        &::before{
          content:'';
            position: absolute;
            right: 0;
            top: -50px;
            width: 50px;
            height: 50px;
            background-color:transparent;
            border-radius: 50%;
            box-shadow: 35px 35px 0 10px rgb(255, 255, 255) ;
            pointer-events: none;
            
        }
        
        &::after{
            content: '';
           
            position: absolute;
            right: 0;
            bottom: -50px;
            width: 50px;
            height: 50px;
            background-color: transparent;
            border-radius: 50%;
             box-shadow:35px -35px 0 10px white;
             pointer-events: none;
             
             
             
            }
        }
            
        }

        .iconBar:hover{
            color: $blue !important;
    }
    
    
}