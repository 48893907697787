$grid-template-columns: 100px 1fr; 


//color
$blue: rgb(164, 164, 234);
$yellow: rgb(254, 254, 154);
$red: rgb(241, 148, 148) ;
$green: rgb(186, 237, 186); 
$gray: rgb(134, 111, 111);
$ff:black;

$media: "screen and (max-width:800px) ";

